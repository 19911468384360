import React, { lazy, Suspense } from 'react';
import 'flowbite';
import { Routes, Route } from 'react-router-dom';

// Lazy load components
const WebhookHandler = lazy(() => import('./assets/js/WebhookHandler.js'));
const IndexPage = lazy(() => import('./pages/Index.js'));
const HomePricingPage = lazy(() => import('./pages/Home_pricing.js'));
const EventPricingPage = lazy(() => import('./pages/Event_pricing.js'));
const ContactPage = lazy(() => import('./pages/Contact.js'));
const AboutUsPage = lazy(() => import('./pages/AboutUs.js'));
const SuccessPage = lazy(() => import('./pages/Success.js'));
const ThankYouPage = lazy(() => import('./pages/ThankYou.js'));
const ErrorPage = lazy(() => import('./pages/404.js'));
const SongsPage = lazy(() => import("./pages/SongPage"));

function App() {
    return (
        <Suspense fallback={<div>Loading...</div>}>
            <Routes>
                <Route path="/" element={<IndexPage />} />
                <Route path="/home-pricing" element={<HomePricingPage />} />
                <Route path="/event-pricing" element={<EventPricingPage />} />
                <Route path="/contact" element={<ContactPage />} />
                <Route path="/about-us" element={<AboutUsPage />} />
                <Route path="/success" element={<SuccessPage />} />
                <Route path="/thank-you" element={<ThankYouPage />} />
                <Route path="/stripe-webhook" element={<WebhookHandler />} />
                <Route path="/songs" element={<SongsPage />} />
                <Route path="*" element={<ErrorPage />} />
            </Routes>
        </Suspense>
    );
}

export default App;